import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import MenuBook from '@material-ui/icons/MenuBook';
import LayersIcon from '@material-ui/icons/Layers';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PsychologyIcon from '@mui/icons-material/Psychology';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { CortexIcon } from '@cortexapps/backstage-plugin';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import GroupIcon from '@material-ui/icons/People';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CategoryIcon from '@material-ui/icons/Category';
import DeployIcon from '@material-ui/icons/Telegram';
import { NewAnnouncementBanner } from '@procore-oss/backstage-plugin-announcements';
import { FeatureFlagged } from '@backstage/core-app-api';
import LogoIcon from './LogoIcon';
import LogoFull from './LogoFull';

sidebarConfig.drawerWidthOpen = 230;

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 18,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup
          label="Search"
          icon={<SearchIcon fontSize="small" />}
          to="/search"
        >
          <SidebarItem
            icon={() => <SearchIcon fontSize="small" />}
            to="/search"
            text="Search"
          />
        </SidebarGroup>

        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon fontSize="small" />}>
          {/* Docs */}
          <SidebarItem
            icon={() => <MenuBook fontSize="small" />}
            to="technology"
            text="Technology at EFG"
          />
          <FeatureFlagged with="learning-path">
            <SidebarItem
              icon={() => <PsychologyIcon fontSize="small" />}
              to="/learning-path"
              text="Learning Paths"
            />
          </FeatureFlagged>
          <FeatureFlagged with="leaderboard">
            <SidebarItem
              icon={() => <LeaderboardIcon fontSize="small" />}
              to="/leaderboard"
              text="Leaderboard"
            />
          </FeatureFlagged>
          <SidebarDivider />
          <MyGroupsSidebarItem
            singularTitle="My Team"
            pluralTitle="My Teams"
            icon={() => <GroupIcon fontSize="small" />}
          />
          <SidebarItem
            icon={() => <CategoryIcon fontSize="small" />}
            to="catalog"
            text="Catalog"
          />
          <SidebarDivider />

          <SidebarItem
            icon={() => <LayersIcon fontSize="small" />}
            to="explore"
            text="Explore"
          >
            <SidebarSubmenu title="Explore">
              <SidebarDivider />
              <SidebarSubmenuItem
                to="announcements"
                title="Announcements"
                icon={() => <AnnouncementIcon fontSize="small" />}
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                icon={() => <DeployIcon fontSize="small" />}
                to="animals-deploy"
                title="Deployment History"
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                // @ts-ignore - lets solve the icon type issue when migrating to mui5/6
                icon={() => <CortexIcon fontSize="small" />}
                to="cortex"
                title="Scorecards"
              />
              <SidebarDivider />

              <SidebarSubmenuItem
                to="explore/groups"
                title="Org Chart"
                icon={() => <AccountTreeIcon fontSize="small" />}
              />
              <SidebarSubmenuItem
                to="explore/teams"
                title="Teams"
                icon={() => <GroupsIcon fontSize="small" />}
              />

              <SidebarDivider />
              <SidebarSubmenuItem
                icon={() => <ExtensionIcon fontSize="small" />}
                to="api-docs"
                title="API Specs"
              />
              <SidebarSubmenuItem
                icon={() => <LibraryBooks fontSize="small" />}
                to="docs"
                title="Docs Browser"
              />

              <SidebarDivider />
              <SidebarSubmenuItem
                icon={() => <BuildIcon fontSize="small" />}
                to="entity-validation"
                title="Entity Validator"
              />
              <SidebarSubmenuItem
                icon={() => <CategoryIcon fontSize="small" />}
                to="catalog-unprocessed-entities"
                title="Import Issues"
              />
            </SidebarSubmenu>
          </SidebarItem>
          <SidebarDivider />

          <SidebarItem
            icon={() => <CreateComponentIcon fontSize="small" />}
            to="/create"
            text="Self Service"
          />
        </SidebarGroup>
        <SidebarSpace />

        {/* End global nav */}

        <SidebarDivider />

        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      <NewAnnouncementBanner variant="floating" />
      {children}
    </SidebarPage>
  );
};
