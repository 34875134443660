export enum ladderLevels {
  NA,
  Bronze,
  Silver,
  Gold,
}

export type LadderLevelHistogram = Map<ladderLevels, number>;

/**
 * TeamScore contains the information about a team's average score, median rank, and details for each scorecard
 */
export interface TeamScore {
  rank: number;
  teamEntityRef: string; // group:team/cloudgs
  averageScore: number; // 0-1
  medianLadderLevel: ladderLevels;
  details: ScoreDetail[];
}

/**
 * ScoreDetail is the average score for a given Scorecard and the number of services at each level
 */
export interface ScoreDetail {
  scorecardId: number;
  scorecardName: string;
  scorePercentage: number; // 0-1
  // [1,1,2,3] means 4 services: 2 bronze, 1 silver, 1 gold service
  serviceLevels: ladderLevels[];
}
