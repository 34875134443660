import React from 'react';
import { ScoreDetail } from '@efg/backstage-plugin-leaderboard-common';
import {
  makeStyles,
  TableCell,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Gauge, InfoCard, Progress } from '@backstage/core-components';
import { BackstageTheme } from '@backstage/theme';
import { isEmpty } from 'lodash';
import { RankBadge } from './RankBadgeComponent';

const useStyles = makeStyles<BackstageTheme>(theme => ({
  tableRow: {
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&:hover': {
      background: `${theme.palette.background.default}!important`,
    },
  },
  unselected: {
    background: `${theme.palette.background.paper}!important`,
  },
  selected: {
    background: `${theme.palette.background.default}!important`,
  },
  gaugeBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    gap: theme.spacing(0.5),
    margin: 'auto',
  },
}));

interface EntityScorecardsCardRowProps {
  score: ScoreDetail;
  ladder: number;
  selected: boolean;
  onSelect: () => void;
}

export const EntityScorecardsCardRow = ({
  score,
  ladder,
  selected,
  onSelect,
}: EntityScorecardsCardRowProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableCell
        className={`${classes.tableRow} ${
          selected ? classes.selected : classes.unselected
        }`}
        onClick={onSelect}
      >
        <Box className={classes.gaugeBox}>
          <Typography variant="body1" align="center">
            {score.scorecardName}
          </Typography>
          <Gauge size="small" value={score.scorePercentage} />
          <RankBadge level={ladder} />
        </Box>
      </TableCell>
    </React.Fragment>
  );
};

interface EntityScorecardsCardProps {
  title?: string;
  ladder: number;
  scores?: ScoreDetail[];
  loading: boolean;
  selectedScorecardId?: number;
  onSelect: (scorecardId: number) => void;
}

export const EntityScorecardsCard = ({
  title,
  scores,
  ladder,
  loading,
  selectedScorecardId,
  onSelect,
}: EntityScorecardsCardProps) => {
  const classes = useStyles();

  return (
    <InfoCard title={title}>
      <Table className={classes.table}>
        <TableBody>
          {loading ? (
            <Progress />
          ) : (
            isEmpty(scores) && (
              <Typography variant="subtitle1">No Scorecards</Typography>
            )
          )}
          {scores?.map(score => (
            <EntityScorecardsCardRow
              key={`EntityScorecardsCardRow-${score.scorecardId}`}
              score={score}
              ladder={ladder}
              onSelect={() => onSelect(score.scorecardId)}
              selected={selectedScorecardId === score.scorecardId}
            />
          ))}
        </TableBody>
      </Table>
    </InfoCard>
  );
};
