import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { EntityValidationPage } from '@backstage-community/plugin-entity-validation';
import {
  techdocsPlugin,
  TechDocsIndexPage,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ExpandableNavigation,
  LightBox,
  ReportIssue,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { ExplorePage } from '@backstage-community/plugin-explore';
import {
  AlertDisplay,
  IdentityProviders,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  gitlabAuthApiRef,
  useApi,
  configApiRef,
  SignInPageProps,
  BackstagePlugin,
  googleAuthApiRef,
} from '@backstage/core-plugin-api';
import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import PetsIcon from '@material-ui/icons/Pets';
import CssBaseline from '@mui/material/CssBaseline';
import { AnimalsDeploymentsPage } from '@efg/plugin-animals-deploy';
import {
  TechdocRoadmapsAndGoldenpathEditorPage,
  TechdocRoadmapsAndGoldenpathDefaultHomePage,
  TechdocRoadmapsAndGoldenpathCreatorPage,
  TechdocRoadmapsAndGoldenpathViewerPage,
} from '@efg/plugin-techdoc-goldenpath-and-roadmaps';
import { UnifiedThemeProvider } from '@backstage/theme';
import { incidentPlugin } from '@efg/incident-io-backstage';
import { DoraMetricsPage } from '@efg/backstage-plugin-dora-metrics';
import { CostMetricsPage } from '@efg/backstage-plugin-cost-metrics';
import { LeaderboardPage } from '@efg/backstage-plugin-leaderboard';
import { CortexPage } from './cortex';
import { darkEFGTheme, lightEFGTheme } from './theme/efg';
import { CustomImportFlow } from './components/catalog-import/CustomImportFlow';
import {
  grafana,
  slack,
  Jira,
  Confluence,
  Incident,
  Kubecost,
} from './assets/icons/CustomIcons';
import { HomePage } from './components/home/HomePage';
import { explorePage } from './components/explore/ExplorePage';
import { Root } from './components/Root';
import { searchPage } from './components/search/SearchPage';
import { entityPage } from './components/catalog/EntityPage';
import { apis } from './apis';
import { TechnologyAtEFG } from './components/tech-at-efg/TechAtEFG';
import { DefaultNotAuthorized } from './components/pages/NotAuthorized';

const OptionalSignInPage = (props: SignInPageProps) => {
  const config = useApi(configApiRef);

  const authProviders: IdentityProviders = [
    {
      id: 'google-auth-provider',
      title: 'Google',
      message: 'Sign in via Google',
      apiRef: googleAuthApiRef,
    },
  ];
  if (config.getString('auth.environment') === 'development') {
    authProviders.push('guest');
    authProviders.push({
      id: 'gitlab-auth-provider',
      title: 'GitLab',
      message: 'Sign in via Gitlab',
      apiRef: gitlabAuthApiRef,
    });
  }

  return <SignInPage {...props} providers={authProviders} align="center" />;
};

const plugins: BackstagePlugin[] = [
  // this should not be required
  incidentPlugin,
];

const app = createApp({
  icons: {
    grafana: grafana,
    slack: slack,
    animals: PetsIcon,
    jira: Jira,
    confluence: Confluence,
    incident: Incident,
    kubecost: Kubecost,
  },
  apis,
  components: {
    SignInPage: OptionalSignInPage,
  },
  featureFlags: [
    {
      pluginId: '',
      name: 'learning-path',
      description:
        'Enables Learning Homa Page and add it to the main navigation tab',
    },
    {
      pluginId: '',
      name: 'use-version-api',
      description:
        'Enables versions API, deployment are available in components and service and use the new backend',
    },
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'efg-dark',
      title: 'EFG Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={darkEFGTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'efg-light',
      title: 'EFG Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={lightEFGTheme}>
          <CssBaseline>{children}</CssBaseline>
        </UnifiedThemeProvider>
      ),
    },
  ],
  plugins: plugins,
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route path="/technology" element={<TechnologyAtEFG />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <ExpandableNavigation />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <RequirePermission
          permission={catalogEntityCreatePermission}
          errorPage={DefaultNotAuthorized}
        >
          <ScaffolderPage />
        </RequirePermission>
      }
    />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>
    <Route
      path="/learning-path"
      element={<TechdocRoadmapsAndGoldenpathDefaultHomePage />}
    />
    <Route
      path="/learning-path/create"
      element={<TechdocRoadmapsAndGoldenpathCreatorPage />}
    />
    <Route
      path="/learning-path/editor/:namespace/:kind/:name"
      element={<TechdocRoadmapsAndGoldenpathEditorPage />}
    />
    <Route
      path="/learning-path/viewer/:namespace/:kind/:name"
      element={<TechdocRoadmapsAndGoldenpathViewerPage />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission
          permission={catalogEntityCreatePermission}
          errorPage={DefaultNotAuthorized}
        >
          <CatalogImportPage />
        </RequirePermission>
      }
    >
      <CustomImportFlow />
    </Route>

    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/animals-deploy" element={<AnimalsDeploymentsPage />} />
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route path="/cortex" element={<CortexPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route path="/dora-metrics" element={<DoraMetricsPage />} />
    <Route path="/cost-metrics" element={<CostMetricsPage />} />
    <Route path="/leaderboard" element={<LeaderboardPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
