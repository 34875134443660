import useAsync, { AsyncState } from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { leaderboardApiRef } from '../api/LeaderboardApi';
import { TeamScore } from '@efg/backstage-plugin-leaderboard-common';

/**
 * Return the Ranking of the teams
 */
export function useRanking(): AsyncState<TeamScore[]> {
  const rankingsApi = useApi(leaderboardApiRef);

  return useAsync(async (): Promise<TeamScore[]> => {
    return await rankingsApi.getRanking();
  }, []);
}
