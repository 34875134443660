import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { EntityScorecardsCard } from './EntityScoresCard';
import { EmptyState, WarningPanel } from '@backstage/core-components';
import { useRanking } from '../hooks';

export const TeamScoreComponent = ({}) => {
  const { entity } = useEntity();
  const ranking = useRanking();
  const rank = ranking?.value || [];
  const teamRanking = rank.find(
    team => team.teamEntityRef === stringifyEntityRef(entity),
  );

  if (entity === undefined) {
    return (
      <WarningPanel severity="error" title="Could not load entity.">
        Entity not found
      </WarningPanel>
    );
  }

  if ((ranking.error || ranking.value === undefined) && !ranking.loading) {
    return (
      <WarningPanel severity="error" title="Could not load Scorecards.">
        {ranking.error?.message}
      </WarningPanel>
    );
  }

  if (rank.length === 0 && !ranking.loading) {
    return (
      <EmptyState
        missing="info"
        title="No scorecards to display"
        description="You haven't added any scorecards yet."
      />
    );
  }

  return (
    <EntityScorecardsCard
      title="Cortex Scorecards"
      scores={teamRanking?.details}
      ladder={teamRanking?.medianLadderLevel || 0}
      loading={ranking.loading}
      onSelect={scorecardId => {
        const team = teamRanking?.teamEntityRef.replace(/^group:/, '');
        const cortexUrl = `/cortex/reports/heatmap?headerType=rules&path=%5B"GroupBy"%2C"Team"%5D&path=%5B"Item"%2C"${team}"%5D&scorecardId=${scorecardId}`;
        window.location.href = window.location.origin + cortexUrl;
      }}
    />
  );
};
