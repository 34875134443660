import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { DefaultLeaderboardApi, leaderboardApiRef } from './api/LeaderboardApi';

export const leaderBoardApiFactory = createApiFactory({
  api: leaderboardApiRef,
  deps: {
    fetchApi: fetchApiRef,
  },
  factory: ({ fetchApi }) =>
    new DefaultLeaderboardApi({
      fetchApi: fetchApi,
    }),
});

export const leaderboardPlugin = createPlugin({
  featureFlags: [{ name: 'leaderboard' }],
  apis: [leaderBoardApiFactory],
  id: 'leaderboard',
  routes: {
    root: rootRouteRef,
  },
});

export const LeaderboardPage = leaderboardPlugin.provide(
  createRoutableExtension({
    name: 'LeaderboardPage',
    component: () =>
      import('./components/LeaderboardComponents').then(
        m => m.LeaderboardComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
