import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import {
  ladderLevels,
  RANK_COLORS,
} from '@efg/backstage-plugin-leaderboard-common';

interface RankBadgeProps {
  level: ladderLevels;
}

export const RankBadge = ({
  level = ladderLevels.NA,
  style = {},
}: RankBadgeProps & { style?: React.CSSProperties }) => (
  <Tooltip title={ladderLevels[level]}>
    <Box display="flex" flexDirection="row" alignItems="center" style={style}>
      <StarIcon
        style={{
          color: RANK_COLORS[level],
          marginRight: 4,
        }}
      />
      <Typography variant="body2">{ladderLevels[level]}</Typography>
    </Box>
  </Tooltip>
);
