import {
  CortexScorecardWidgetProps,
  CustomMapping,
  EntityFilterGroup,
  extendableCortexPlugin,
  ExtensionApi,
} from '@cortexapps/backstage-plugin';
import { BackstagePlugin } from '@backstage/core-plugin-api';
import {
  ComponentEntityV1alpha1,
  Entity,
  GroupEntity,
  isComponentEntity,
  isGroupEntity,
  isSystemEntity,
} from '@backstage/catalog-model';
import { EntityFilter } from '@cortexapps/backstage-plugin-extensions';

class ExtensionApiImpl implements ExtensionApi {
  async getAdditionalFilters(): Promise<EntityFilterGroup[]> {
    return [
      {
        name: 'Type',
        groupProperty: (entity: Entity) =>
          entity.spec?.type === null || entity.spec?.type === undefined
            ? undefined
            : [JSON.stringify(entity.spec?.type).replaceAll('"', '')],
      },
    ];
  }

  async getCustomMappings(): Promise<CustomMapping[]> {
    return [
      (entity: Entity) => {
        if (isComponentEntity(entity)) {
          const component = entity as ComponentEntityV1alpha1;
          const system = component.spec.system;
          const extraServiceGroups = system ? [`system:${system}`] : [];
          const sonarqubeAnnotation =
            component.metadata.annotations?.['sonarqube.org/project-key'];
          const sonarProjectKey = sonarqubeAnnotation?.startsWith(
            'eslfaceitgroup_',
          )
            ? sonarqubeAnnotation
            : `eslfaceitgroup_${sonarqubeAnnotation}`;
          return {
            'x-cortex-service-groups': [
              ...(component.metadata.tags ?? []),
              ...extraServiceGroups,
            ],
            'x-cortex-type': 'component',
            'x-cortex-definition': [],
            'x-cortex-static-analysis': {
              sonarqube: {
                project: sonarProjectKey,
              },
            },
          };
        } else if (isSystemEntity(entity)) {
          return {
            'x-cortex-service-groups': [...(entity.metadata.tags ?? [])],
            'x-cortex-type': 'system',
            'x-cortex-definition': [],
          };
        } else if (isGroupEntity(entity)) {
          const team = entity as GroupEntity;
          if (team.spec?.type === 'team') {
            // adding some extra properties for a team

            // the library is missing support for the jira projects
            // const jiraProjectsKey = team.metadata.annotations?.['jira.com/project-key'];
            const slackAlertsChannelName =
              team.metadata.annotations?.['efg.gg/slack-alert-channel'] ===
              undefined
                ? `alerts-team-${team.metadata.name}`
                : team.metadata.annotations?.['efg.gg/slack-alerts-channel'];
            const slackSupportChannelName =
              team.metadata.annotations?.['efg.gg/slack-support-channel'] ===
              undefined
                ? `support-team-${team.metadata.name}`
                : team.metadata.annotations?.['efg.gg/slack-support-channel'];
            return {
              'x-cortex-incident-io': {
                customFields: [
                  { name: 'Affected Team', value: team.metadata.name },
                ],
              },
              // 'x-cortex-issues': {
              //   jira: {
              //     projects: [
              //         {name: jiraProjectsKey},
              //       ],
              //   },
              // },
              'x-cortex-slack': {
                channels: [
                  { name: slackAlertsChannelName, notificationsEnabled: true },
                  {
                    name: slackSupportChannelName,
                    notificationsEnabled: false,
                  },
                ],
              },
            };
          }
        }
        // we do not want to specify any type so Cortex picks
        return {
          'x-cortex-service-groups': [...(entity.metadata.tags ?? [])],
        };
      },
    ];
  }

  async getSyncEntityFilter(): Promise<EntityFilter> {
    return {
      kinds: ['Domain', 'Component', 'System', 'User', 'Group'],
      entityFilter: (entity: Entity) => {
        if (isComponentEntity(entity)) {
          // only services are exported to Cortex
          return entity.spec?.type === null || entity.spec?.type === undefined
            ? false
            : entity.spec.type === 'service';
        }

        // everything else is fine
        return true;
      },
    };
  }
}

export const {
  plugin,
  EntityCortexContent,
  CortexPage,
  CortexScorecardWidget,
}: {
  plugin: BackstagePlugin<{}, {}, {}>;
  CortexPage: () => React.JSX.Element;
  EntityCortexContent: () => React.JSX.Element;
  CortexScorecardWidget: ({
    includeFilters,
    excludeFilters,
  }: CortexScorecardWidgetProps) => React.JSX.Element;
  CortexGroupActionItemsWidget: () => React.JSX.Element;
  SystemCortexContent: () => React.JSX.Element;
} = extendableCortexPlugin({}, () => new ExtensionApiImpl());
